import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Task } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getTaskRef } from 'src/utils/firebase';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { updateDoc } from 'firebase/firestore';
import { taskStatus } from 'src/constants/tasks';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import createExchangePayments from 'src/api/transaction/create-exchange-payments';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<Task>);

	const taskRef = getTaskRef(payload.id);

	const handleContinue = async () => {
		try {
			setIsLoading(true);

			await updateDoc<unknown>(taskRef, { status: taskStatus.done, completedAt: new Date() });

			await createExchangePayments(transaction.id);
			dispatch(getTransactionTrackerThunk({ id: transaction.id }));
			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>EXCHANGE COMPLETED ON {moment(new Date()).format('DD/MM/YYYY')}</Typography>
			<Typography variant='subtitle1' mb='7px'>
				Setting this transaction as exchanged will lock the transaction and send final invoices to all participants.
			</Typography>
			<Typography variant='subtitle1' mb='7px'>
				⚠ <b>This action cannot be undone.</b> Do you still want to proceed?
			</Typography>
			<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
				<LoadingButton variant='outlined' onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' loading={isLoading} onClick={handleContinue}>
					Confirm
				</LoadingButton>
			</Box>
		</Box>
	);
};

const ConfirmExchangeCompleteModal = () => (
	<Modal name={modals.confirmExchangeComplete} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default ConfirmExchangeCompleteModal;
