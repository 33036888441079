import { getDocs, query, QueryDocumentSnapshot, where } from 'firebase/firestore';
import { propertyQuestionnaireCollection, transactionQuestionnaireCollection } from 'src/constants/firestore';
import { FullQuestionnaireByStringType, QuestionnaireTypeEnum } from 'src/types';
import { getPropertyRef, getTransactionRef } from 'src/utils/firebase';

export default async function getQuestionnaireById<T extends QuestionnaireTypeEnum>(id: string, type: T) {
	const isProperty = type === QuestionnaireTypeEnum.PROPERTY;

	const innerRef = isProperty ? getPropertyRef(id) : getTransactionRef(id);
	const questionnarieQuery = query<unknown>(
		type === QuestionnaireTypeEnum.PROPERTY ? propertyQuestionnaireCollection : transactionQuestionnaireCollection,
		where(type === QuestionnaireTypeEnum.PROPERTY ? 'propertyRef' : 'transaction', '==', innerRef),
	);
	const {
		docs: [questionnaire],
	} = await getDocs(questionnarieQuery);

	if (!questionnaire) throw new Error(`Questionnaire (${type}) ${id} doesn't exist`);

	return questionnaire as QueryDocumentSnapshot<FullQuestionnaireByStringType<T>>;
}
