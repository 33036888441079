import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { getPropertyOnboardingsCollection, propertyOnboardingsCollectionGroup } from 'src/constants/firestore';
import { PropertyOnboarding } from 'src/types';
import { getUserRef } from '..';

export const findPropertyOnboarding = async (
	userId: string,
	propertyId: string,
): Promise<DocumentSnapshot<PropertyOnboarding> | null> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(getPropertyOnboardingsCollection(propertyId), where('userRef', '==', userRef)));

	return docs[0] ?? null;
};

export const findAllPropertyOnboardings = async (userId: string): Promise<DocumentSnapshot<PropertyOnboarding>[]> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(propertyOnboardingsCollectionGroup, where('userRef', '==', userRef)));

	return docs;
};
