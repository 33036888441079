import { getProperty } from 'src/utils/firebase';
import checkIsPropertyOnSale from './check-is-not-on-sale';
import addTransaction, { Payload as AddTransactionPayload } from '../transaction/add';

const sellProperty = async (
	payload: AddTransactionPayload,
	onSaleErrorMessage = 'Property is already on the market',
) => {
	const { data } = await getProperty(payload.propertyId);

	const onSale = await checkIsPropertyOnSale(data.uprn);

	if (onSale) throw new Error(onSaleErrorMessage);

	const { id } = await addTransaction(payload);

	return id;
};

export default sellProperty;
