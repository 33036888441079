import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { getUserChangeEmailRequestsCollection } from 'src/constants/firestore';
import { UserChangeEmailRequest, UserChangeEmailRequestStatusEnum } from 'src/types';
import { getUserRef } from '..';

export const findUserChangeEmailRequest = async (
	userId: string,
): Promise<DocumentSnapshot<UserChangeEmailRequest> | null> => {
	const { docs } = await getDocs(
		query(
			getUserChangeEmailRequestsCollection(userId),
			where('userRef', '==', getUserRef(userId)),
			where('status', '!=', UserChangeEmailRequestStatusEnum.ARCHIVED),
		),
	);

	return docs[0] ?? null;
};
