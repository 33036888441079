import { CollectionReference, collection, collectionGroup } from 'firebase/firestore';

import { db } from 'src/firebaseConfig';
import {
	Branch,
	IQuestionnaireProperty,
	IQuestionnaireTransaction,
	Invitation,
	Property,
	Transaction,
	TransactionInvitation,
	TransactionOffer,
	User,
	Payment,
	PropertyOnboarding,
	VerificationSession,
	Register,
	PropertyListing,
	PropertyPrice,
	UserOnboarding,
	Epc,
	TransactionOnboarding,
	UserChangeEmailRequest,
	TransactionSummary,
	Tracker,
} from 'src/types';
import { RoomSection, Task } from 'src/redux/types';
import {
	BRANCHES_COLLECTION_NAME,
	COUNTRIES_COLLECTION_NAME,
	EPC_COLLECTION_NAME,
	INVITATIONS_COLLECTION_NAME,
	LAW_HIERARCHIES_COLLECTION_NAME,
	PAYMENTS_COLLECTION_NAME,
	PROFESSIONS_COLLECTION_NAME,
	PROPERTIES_COLLECTION_NAME,
	PROPERTY_LISTINGS_COLLECTION_NAME,
	TRANSACTION_ONBOARDING_COLLECTION_NAME,
	PROPERTY_PRICES_COLLECTION_NAME,
	QUESTIONNAIRE_PROPERTY_COLLECTION_NAME,
	QUESTIONNAIRE_TRANSACTION_COLLECTION_NAME,
	REALTY_HIERARCHIES_COLLECTION_NAME,
	REGISTERS_COLLECTION_NAME,
	ROLES_COLLECTION_NAME,
	TRANSACTIONS_COLLECTION_NAME,
	TRANSACTION_INVITATIONS_COLLECTION_NAME,
	TRANSACTION_OFFERS_COLLECTION_NAME,
	USERS_COLLECTION_NAME,
	USER_ONBOARDING_COLLECTION_NAME,
	VERIFICATION_SESSIONS_COLLECTION_NAME,
	PROPERTY_ONBOARDINGS_COLLECTION_NAME,
	USER_CHANGE_EMAIL_REQUESTS_COLLECTION_NAME,
	ROOMS_COLLECTION_NAME,
	TRANSACTION_SUMMARIES_COLLECTION_NAME,
	TRANSACTION_TASKS_COLLECTION_NAME,
	TRACKERS_COLLECTION_NAME,
} from './collections';

export const rolesCollection = collection(db, ROLES_COLLECTION_NAME) as CollectionReference<RRole>;
export const epcCollection = collection(db, EPC_COLLECTION_NAME) as CollectionReference<Epc>;
export const countriesCollection = collection(db, COUNTRIES_COLLECTION_NAME) as CollectionReference<RCountry>;
export const branchesCollection = collection(db, BRANCHES_COLLECTION_NAME) as CollectionReference<Branch>;
export const lawHierarchiesCollection = collection(
	db,
	LAW_HIERARCHIES_COLLECTION_NAME,
) as CollectionReference<LBusiness>;
export const realtyHierarchiesCollection = collection(
	db,
	REALTY_HIERARCHIES_COLLECTION_NAME,
) as CollectionReference<RBusiness>;
export const propertiesCollection = collection(db, PROPERTIES_COLLECTION_NAME) as CollectionReference<Property>;
export const verificationSessionsCollection = collection(
	db,
	VERIFICATION_SESSIONS_COLLECTION_NAME,
) as CollectionReference<VerificationSession>;
export const userChangeEmailRequestsCollectionGroup = collectionGroup(
	db,
	USER_CHANGE_EMAIL_REQUESTS_COLLECTION_NAME,
) as CollectionReference<UserChangeEmailRequest>;
export const propertyOnboardingCollection = collection(
	db,
	PROPERTY_ONBOARDINGS_COLLECTION_NAME,
) as CollectionReference<PropertyOnboarding>;
export const propertyOnboardingsCollectionGroup = collectionGroup(
	db,
	PROPERTY_ONBOARDINGS_COLLECTION_NAME,
) as CollectionReference<PropertyOnboarding>;
export const transactionOnboardingCollection = collection(
	db,
	TRANSACTION_ONBOARDING_COLLECTION_NAME,
) as CollectionReference<TransactionOnboarding>;
export const propertyListingsCollection = collection(
	db,
	PROPERTY_LISTINGS_COLLECTION_NAME,
) as CollectionReference<PropertyListing>;
export const propertyPricesCollection = collection(
	db,
	PROPERTY_PRICES_COLLECTION_NAME,
) as CollectionReference<PropertyPrice>;
export const userOnboardingCollection = collection(
	db,
	USER_ONBOARDING_COLLECTION_NAME,
) as CollectionReference<UserOnboarding>;
export const propertyQuestionnaireCollection = collection(
	db,
	QUESTIONNAIRE_PROPERTY_COLLECTION_NAME,
) as CollectionReference<IQuestionnaireProperty>;
export const transactionQuestionnaireCollection = collection(
	db,
	QUESTIONNAIRE_TRANSACTION_COLLECTION_NAME,
) as CollectionReference<IQuestionnaireTransaction>;
export const usersCollection = collection(db, USERS_COLLECTION_NAME) as CollectionReference<User>;
export const professionsCollection = collection(db, PROFESSIONS_COLLECTION_NAME) as CollectionReference<RProfession>;
export const paymentsCollection = collection(db, PAYMENTS_COLLECTION_NAME) as CollectionReference<Payment>;
export const transactionsCollection = collection(db, TRANSACTIONS_COLLECTION_NAME) as CollectionReference<Transaction>;
export const trackersCollection = collection(db, TRACKERS_COLLECTION_NAME) as CollectionReference<Tracker>;
export const transactionInvitationsCollection = collection(
	db,
	TRANSACTION_INVITATIONS_COLLECTION_NAME,
) as CollectionReference<TransactionInvitation>;
export const invitationsCollection = collection(db, INVITATIONS_COLLECTION_NAME) as CollectionReference<Invitation>;
export const transactionOffersCollection = collection(
	db,
	TRANSACTION_OFFERS_COLLECTION_NAME,
) as CollectionReference<TransactionOffer>;
export const registersCollection = collection(db, REGISTERS_COLLECTION_NAME) as CollectionReference<Register>;
export const roomsCollection = collection(db, ROOMS_COLLECTION_NAME) as CollectionReference<
	Record<string, RoomSection>
>;
export const transactionSummariesCollection = collection(
	db,
	TRANSACTION_SUMMARIES_COLLECTION_NAME,
) as CollectionReference<TransactionSummary>;
export const transactionTasksCollection = collection(
	db,
	TRANSACTION_TASKS_COLLECTION_NAME,
) as CollectionReference<Task>;

export const getPropertyOnboardingsCollection = (propertyId: string) =>
	collection(
		db,
		PROPERTIES_COLLECTION_NAME,
		propertyId,
		PROPERTY_ONBOARDINGS_COLLECTION_NAME,
	) as CollectionReference<PropertyOnboarding>;

export const getUserChangeEmailRequestsCollection = (userId: string) =>
	collection(
		db,
		USERS_COLLECTION_NAME,
		userId,
		USER_CHANGE_EMAIL_REQUESTS_COLLECTION_NAME,
	) as CollectionReference<UserChangeEmailRequest>;
