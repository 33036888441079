import { call } from '../@';

export type Payload = {
	uprn: number;
	address: {
		postcode: string;
		address: string;
		paon: string | number;
		saon: string | null;
		street: string;
		locality: string | null;
		townCity: string;
		district: string | null;
		county: string;
		latitude: number;
		longitude: number;
		displayAddress: string;
	};
};

export default async function addProperty(payload: Payload) {
	return await call('property-add', payload);
}
