import { ITransactionParticipants } from 'src/redux/types';
import { Property } from 'src/types';

export const movingOut = (participants: ITransactionParticipants, property: Property) => {
	const proprietorNames = participants.sellers.reduce<string[]>((acc, seller) => {
		if (seller.relationship !== 'proprietor' || !seller.isApproved) return acc;

		acc.push(`${seller.givenNames} ${seller.lastNames}`);

		return acc;
	}, []);

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Moving Out card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'timing_financing',
				elements: [
					{
						type: 'boolean',
						name: 'chain_yn',
						title: 'Do any of the sellers have any related transactions? *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'paneldynamic',
						name: 'chain',
						visibleIf: '{chain_yn} = true',
						title: 'Related transactions',
						description: 'Please provide details of all related transactions',
						hideNumber: true,
						requiredIf: '{chain_yn} = true',
						templateElements: [
							{
								type: 'tagbox',
								name: 'buyers',
								title: 'Person(s)',
								requiredIf: '{chain_yn} = true',
								choices: proprietorNames,
							},
							{
								type: 'multipletext',
								name: 'property',
								title: 'Property details',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
								items: [
									{
										name: 'street_address',
										isRequired: true,
										placeholder: 'Flat 12, Churchill Lodge',
										title: 'Street address *',
									},
									{
										name: 'postcode',
										isRequired: true,
										placeholder: 'HP5 1AQ',
										title: 'Postcode *',
									},
									{
										name: 'town_city',
										isRequired: true,
										placeholder: 'Chesham',
										title: 'Town/City *',
									},
									{
										name: 'county_province',
										isRequired: true,
										placeholder: 'Buckinghamshire',
										title: 'County/Province *',
									},
									{
										name: 'state_region',
										isRequired: true,
										placeholder: 'England',
										title: 'State/Region *',
									},
									{
										name: 'country',
										isRequired: true,
										placeholder: 'United Kingdom',
										title: 'Country *',
									},
								],
							},
							{
								type: 'multipletext',
								name: 'buyers_solicitor',
								title: 'Solicitor details',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
								items: [
									{
										name: 'given_names',
										isRequired: true,
										title: 'Given name(s) *',
									},
									{
										name: 'last_names',
										isRequired: true,
										title: 'Last name(s) *',
									},
									{
										name: 'company',
										isRequired: true,
										title: 'Company *',
									},
									{
										name: 'address',
										title: 'Address',
									},
									{
										name: 'email',
										isRequired: true,
										title: 'Email *',
									},
									{
										name: 'mobile',
										isRequired: true,
										title: 'Mobile *',
									},
								],
							},
							{
								type: 'comment',
								name: 'desc',
								title: 'Tell us more *',
								description: 'e.g. what stage you are at and if there have been any hold-ups',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'boolean',
								name: 'chainbound_yn',
								title: `Does this related transaction require completion on the same day as the sale of ${
									property.address.displayAddress ?? property.name
								}? *`,
								isRequired: true,
							},
							{
								type: 'text',
								name: 'completion_date',
								visible: false,
								visibleIf: '{chain[0].chainbound_yn} = false',
								title: 'What is the expected completion date? *',
								titleLocation: 'left',
								hideNumber: true,
								isRequired: true,
								inputType: 'date',
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add new transaction',
					},
					{
						type: 'boolean',
						name: 'moving_requirements_yn',
						title: 'Do you have any special requirements about a move-out date? *',
						hideNumber: true,
						isRequired: true,
						labelFalse: 'ASAP',
					},
					{
						type: 'comment',
						name: 'moving_requirements_desc',
						visibleIf: '{moving_requirements_yn} = true',
						title: 'Please give details',
						hideNumber: true,
					},
					{
						type: 'radiogroup',
						name: 'mortgage_sufficient',
						title: 'Will the sale price be sufficient to repay all mortgages and charges secured on the property? *',
						hideNumber: true,
						isRequired: true,
						choices: [
							{
								value: 'yes',
								text: 'Yes',
							},
							{
								value: 'no',
								text: 'No',
							},
							{
								value: 'no_mortgage',
								text: 'No mortgage',
							},
						],
						colCount: 3,
					},
				],
				title: 'Timing and financing',
			},
			{
				name: 'occupiers',
				elements: [
					{
						type: 'boolean',
						name: 'occupiers_seller_yn',
						title: 'Do any of the sellers live at the property? *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'boolean',
						name: 'occupiers_others_yn',
						title: 'Does anyone else, aged 17 or over, live at the property? *',
						description: 'Sellers are excluded',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'panel',
						name: 'occupiers_others',
						elements: [
							{
								type: 'paneldynamic',
								name: 'occupiers_others_names',
								visibleIf: '{occupiers_others_yn} = true',
								title: 'Please list all occupiers aged 17 or over *',
								description: 'Sellers are excluded',
								hideNumber: true,
								requiredIf: '{occupiers_others_yn} = true',
								templateElements: [
									{
										type: 'multipletext',
										name: 'occupier',
										visibleIf: '{occupiers_others_yn} = true',
										title: 'Occupier',
										requiredIf: '{occupiers_others_yn} = true',
										items: [
											{
												name: 'given_names',
												title: 'Given name(s)',
											},
											{
												name: 'last_names',
												title: 'Last name(s)',
											},
										],
									},
								],
								panelCount: 1,
								minPanelCount: 1,
								panelAddText: 'Add another occupier',
							},
							{
								type: 'boolean',
								name: 'occupiers_tenants_yn',
								visibleIf: '{occupiers_others_yn} = true',
								title: 'Are any of the occupiers who are aged 17 or over, tenants or lodgers? *',
								description: 'Sellers are excluded',
								hideNumber: true,
								requiredIf: '{occupiers_others_yn} = true',
							},
							{
								type: 'boolean',
								name: 'vacant_possession',
								visibleIf: '{occupiers_others_yn} = true',
								title: 'Is the property being sold with vacant possession? *',
								description:
									'“Vacant possession” means the property is empty of people and their belongings (except for anything agreed to stay) on the day of completion.',
								hideNumber: true,
								requiredIf: '{occupiers_others_yn} = true',
							},
							{
								type: 'matrix',
								name: 'vacant_possession_occupiers',
								visible: false,
								visibleIf: '{occupiers_others_yn} = true and {vacant_possession} = true',
								title: 'Have all the occupiers aged 17 or over agreed to: *',
								hideNumber: true,
								requiredIf: '{occupiers_others_yn} = true and {vacant_possession} = true',
								columns: [
									{
										value: 'yes',
										text: 'Yes',
									},
									{
										value: 'no',
										text: 'No',
									},
								],
								rows: [
									{
										value: 'leave',
										text: 'Leave before completion?',
									},
									{
										value: 'sign',
										text: 'Sign the sale contract?',
									},
								],
							},
							{
								type: 'html',
								name: 'vacant_possession_occupiers_note',
								visibleIf:
									"{vacant_possession} = false or  {vacant_possession_occupiers.leave} = 'no' or {vacant_possession_occupiers.sign} = 'no'",
								html: "We'll ask you to upload evidence that the property will be empty on the day of completion in the <b>Documents</b> tab.",
							},
						],
						visibleIf: '{occupiers_others_yn} = true',
						requiredIf: '{occupiers_others_yn} = true',
					},
				],
				title: 'Occupiers',
			},
			{
				name: 'moving_out',
				elements: [
					{
						type: 'boolean',
						name: 'moving_out_clean',
						title:
							'Please confirm that you will remove all rubbish from the property and leave the property in a clean and tidy condition. *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'boolean',
						name: 'moving_out_lights',
						title:
							'Please confirm that, if light fittings are removed, will you replace them with a ceiling rose, flex, bulb holder and blub. *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'boolean',
						name: 'moving_out_care',
						title: 'Please confirm that reasonable care will be taken when removing any other fittings or contents. *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'boolean',
						name: 'moving_out_keys',
						title:
							'Please confirm that keys to all windows and doors and details to alarm codes will be left at the property or with the estate agent. *',
						hideNumber: true,
						isRequired: true,
					},
				],
				title: 'Completion',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
